import React, {useCallback, useEffect, useRef, useState} from 'react';
import {addEdge, Handle, ReactFlow, useEdgesState, useNodesState, useReactFlow,} from '@xyflow/react';
import './css/NodeManager.css';
import OutcomesAndParameters from './OutComesAndParameters';
import Toolbar from './Toolbar';
import {TbFlag} from 'react-icons/tb';
import {CgSandClock} from 'react-icons/cg';
import {MdDone} from 'react-icons/md';
import {BiLoaderAlt} from 'react-icons/bi';

const nodeOrigin = [0.5, 0]; // Defines the node origin

const NodeManager = ({ projectId, searchTerm, disableSearchBar, searchBar }) => {
    const reactFlowWrapper = useRef(null);
    const [selectedNode, setSelectedNode] = useState(null);
    const [highlightPath, setHighlightPath] = useState(false);
    const [highlightedNode, setHighlightedNode] = useState(null);

    // Initialize nodes and edges state
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const { screenToFlowPosition } = useReactFlow();

    const toggleCollapse = () => {
        if (selectedNode) {
            setNodes((nds) =>
                nds.map((node) =>
                    node.id === selectedNode.id
                        ? {
                              ...node,
                              data: {
                                  ...node.data,
                                  collapsed: !node.data.collapsed,
                              },
                          }
                        : node
                )
            );
        }
    };

    // Collapse nodes based on the search term
    const collapseBySearchTerm = useCallback((searchTerm) => {
        setNodes((nds) =>
            nds.map((node) => {
                if (searchTerm === '') {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            collapsed: false
                        },
                    };
                }

                const matchesSearch =
                    node.data.parameters.some((param) => param.name.includes(searchTerm))
                        || node.data.outcomes.some((outcome) => outcome.name.includes(searchTerm));
                return {
                    ...node,
                    data: {
                        ...node.data,
                        collapsed: !matchesSearch
                    },
                };
            })
        );
    }, [setNodes]);

    useEffect(() => {
        collapseBySearchTerm(searchTerm);
    }, [searchTerm, collapseBySearchTerm]);

    const renderNodeContent = (node) => {
        if (node.data.collapsed) {
            return <div>Node</div>;
        }
        const { label, flagStatus } = node.data;

        const flagIcon = {
            flagged: <TbFlag />,
            'in-process': <BiLoaderAlt />,
            waiting: <CgSandClock />,
            done: <MdDone />,
        }[flagStatus]; 

        const flagColors = {
            flagged: 'rgba(255, 102, 102, 0.8)',
            'in-process': 'rgba(255, 204, 102, 0.8)',
            waiting: 'rgba(102, 178, 255, 0.8)',
            done: 'rgba(113,227,113,0.8)',
        };

        return (
            <div className="node-content">
                {label}
                {flagStatus && (
                    <div
                        className="flag-icon"

                    >
                        <div
                            className="flag-bubble"
                            style={{
                                backgroundColor: flagColors[flagStatus],
                            }}
                        >
                            {flagIcon}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    // Fetch the nodes from the database by projectId
    const fetchNodesFromDatabase = async (projectId) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/nodes/project/${projectId}`,
                {
                    method: 'GET',
                }
            );

            if (!response.ok) throw new Error('Failed to fetch nodes');
            const fetchedNodes = await response.json();
            return fetchedNodes;
        } catch (error) {
            console.error('Error fetching nodes:', error);
            return [];
        }
    };

    const saveNodeToDatabase = async (nodeData) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/nodes/create`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(nodeData),
                }
            );
            if (!response.ok) throw new Error('Failed to save node');
            const savedNode = await response.json();
            return savedNode;
        } catch (error) {
            console.error('Error saving node:', error);
            return null;
        }
    };
    /**
     * When the component mounts, this useEffect runs and saves the initial node (Node 0) to the database.
     * It sends the node data to the backend, and when the node is successfully saved, it updates the node's ID with the ID generated by the backend.
     */
    const updateNodePositionInDatabase = async (nodeId, newPosition) => {
        console.log('Position Data:', newPosition.x, newPosition.y); // Add this for debugging
        console.log('Data Type:', typeof newPosition.x, typeof newPosition.y);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/nodes/update/${nodeId}`,
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        positionX: newPosition.x,
                        positionY: newPosition.y,
                    }),
                }
            );
            const responseData = await response.json();
            console.log('Response Status:', response.status); // Log response status
            console.log('Response Body:', responseData); // Log response body
            if (!response.ok) throw new Error('Failed to update node position');
            console.log(
                'Node position updated successfully:',
                nodeId,
                newPosition
            );
        } catch (error) {
            console.error('Error updating node position:', error);
        }
    };

    const updateNodeColorInDatabase = async (nodeId, newColor) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/nodes/update/${nodeId}`,
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ color: newColor }),
                }
            );
            await response.json();
            if (!response.ok) throw new Error('Failed to update node color');
        } catch (error) {
            console.error('Error updating node color:', error);
        }
    };

    const updateNodeFlagStatusInDatabase = async (nodeId, flagStatus) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/nodes/update/${nodeId}`,
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ flagStatus: flagStatus }),
                }
            );

            if (!response.ok)
                throw new Error('Failed to update node flag status');
            await response.json(); // Optional, depending on your response format
            console.log(
                `Node ${nodeId} flag status updated to ${flagStatus} successfully`
            );
        } catch (error) {
            console.error('Error updating node flag status:', error);
        }
    };

    const convertNodesForReactFlow = (fetchedNodes) => {
        return fetchedNodes.map((node) => ({
            id: node.id,
            position: { x: node.positionX, y: node.positionY },
            data: {
                label: (
                    <OutcomesAndParameters
                        nodeId={node.id}
                        initialParameters={node.parameters}
                        initialOutcomes={node.outcomes}
                    />
                ),
                isRoot: !node.parentId, // Node without parentId is root,
                parentId: node.parentId,
                parameters: node.parameters,
                outcomes: node.outcomes,
                flagStatus: node.flagStatus,
            },
            type: 'default',
            origin: nodeOrigin,
            style: { backgroundColor: node.color },
        }));
    }

    // Fetch nodes and initialize state when component mounts
    useEffect(() => {
        const initializeNodesAndEdges = async () => {
            let fetchedNodes = await fetchNodesFromDatabase(projectId);

            if (fetchedNodes.length > 0) {
                // Convert fetched data into the required format for React Flow nodes
                const reactFlowNodes = convertNodesForReactFlow(fetchedNodes);
                setNodes(reactFlowNodes);

                // Create edges based on parent-child relationships
                const reactFlowEdges = fetchedNodes
                    .filter((node) => node.parentId) // Only create edges for nodes with a parentId
                    .map((node) => ({
                        id: `edge-${node.parentId}-${node.id}`,
                        source: node.parentId,
                        target: node.id,
                    }));

                setEdges(reactFlowEdges);
            } else if (nodes.length === 0) {
                const initialNodeData = {
                    parentId: null,
                    projectId: projectId,
                    color: null,
                    flagStatus: null,
                    positionX: 0,
                    positionY: 50,
                    isHighlighted: false,
                    type: 'input',
                };

                const savedNode = await saveNodeToDatabase(initialNodeData);
                if (savedNode) {
                    setNodes(convertNodesForReactFlow([savedNode]));
                }
            }
        };

        initializeNodesAndEdges();
    }, [projectId, setNodes, setEdges, nodes.length]);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges]
    );

    const onConnectEnd = useCallback(
        async (event, connectionState) => {
            if (!connectionState.isValid) {
                const { clientX, clientY } =
                    'changedTouches' in event ? event.changedTouches[0] : event;
                const newNodePosition = screenToFlowPosition({
                    x: clientX,
                    y: clientY,
                });

                const newNodeData = {
                    parentId: connectionState.fromNode.id,
                    projectId: projectId,
                    color: null,
                    flagStatus: null,
                    positionX: newNodePosition.x,
                    positionY: newNodePosition.y,
                    isHighlighted: false,
                };

                const savedNode = await saveNodeToDatabase(newNodeData);

                if (savedNode) {
                    const newNode = {
                        id: savedNode.id,
                        position: {
                            x: savedNode.positionX,
                            y: savedNode.positionY,
                        },
                        data: {
                            label: (
                                <OutcomesAndParameters nodeId={savedNode.id} />
                            ),
                            isRoot: false,
                        },
                        origin: nodeOrigin,
                    };

                    setNodes((nds) => nds.concat(newNode));
                    setEdges((eds) =>
                        eds.concat({
                            id: savedNode.id,
                            source: connectionState.fromNode.id,
                            target: savedNode.id,
                        })
                    );
                }
            }
        },
        [screenToFlowPosition, projectId, setNodes, setEdges]
    );

    const onNodeDragStop = useCallback(
        (event, node) => {
            const newPosition = node.position;

            // Update the node's position in the database
            updateNodePositionInDatabase(node.id, newPosition);

            setNodes((nds) =>
                nds.map((n) =>
                    n.id === node.id ? { ...n, position: newPosition } : n
                )
            );
        },
        [setNodes]
    );

    // Toolbar functions

    const addNode = async () => {
        if (!selectedNode) {
            console.log('No node selected, exiting addNode.');
            return;
        }

        const parentNode = selectedNode;
        if (!parentNode) {
            console.log('Parent node not found, exiting addNode.');
            return;
        }

        const gridSize = 100; // Horizontal spacing between sibling nodes
        const verticalOffset = 250; // Increased vertical offset to place child nodes further down

        // Get all existing child nodes of this parent to position new node in line with them
        const siblings = nodes.filter(
            (node) => node.data.parentId === parentNode.id
        );
        const siblingCount = siblings.length;

        // Define the initial position for the first child node, with increased vertical distance from the parent
        let newNodePosition = {
            x:
                parentNode.position.x +
                siblingCount * gridSize -
                (siblingCount / 2) * gridSize, // Center siblings around parent
            y: parentNode.position.y + verticalOffset,
        };

        // If any existing sibling nodes share this position, offset the new node to the right
        while (
            nodes.some(
                (node) =>
                    node.position.x === newNodePosition.x &&
                    node.position.y === newNodePosition.y
            )
        ) {
            newNodePosition.x += gridSize; // Shift right to avoid overlap
        }

        const newNodeData = {
            parentId: selectedNode.id,
            projectId: projectId,
            color: null,
            flagStatus: null,
            positionX: newNodePosition.x,
            positionY: newNodePosition.y,
            isHighlighted: false,
        };

        const savedNode = await saveNodeToDatabase(newNodeData);
        if (!savedNode) {
            console.log('Failed to save the node to the database.');
            return;
        }

        const newNode = {
            id: savedNode.id,
            position: { x: savedNode.positionX, y: savedNode.positionY },
            data: {
                label: <OutcomesAndParameters nodeId={savedNode.id} />,
                isRoot: false,
            },
            origin: nodeOrigin,
        };

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
            eds.concat({
                id: `edge-${selectedNode.id}-${savedNode.id}`,
                source: selectedNode.id,
                target: savedNode.id,
            })
        );
    };

    const deleteNode = useCallback(async () => {
        if (!selectedNode) {
            console.log('No node selected, exiting deleteNode.');
            return;
        }

        if (selectedNode.data.isRoot) {
            console.log('Cannot delete root node.');
            return;
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/nodes/delete/${selectedNode.id}`,
                { method: 'DELETE' }
            );

            if (!response.ok) {
                throw new Error('Failed to delete node');
            }

            const deletedNodeIds = await response.json();

            setNodes((nds) =>
                nds.filter((node) => !deletedNodeIds.includes(node.id))
            );
            setEdges((eds) =>
                eds.filter(
                    (edge) =>
                        !deletedNodeIds.includes(edge.source) &&
                        !deletedNodeIds.includes(edge.target)
                )
            );

            setSelectedNode(null);
            console.log(
                'Node and its children deleted successfully:',
                deletedNodeIds
            );
        } catch (error) {
            console.error('Error deleting node:', error);
        }
    }, [selectedNode, setNodes, setEdges]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (!selectedNode) return;

            const activeElement = document.activeElement;
            const isEditableField = activeElement.tagName === 'INPUT';

            if (isEditableField) return;

            if (event.key === 'Backspace') {
                if (selectedNode.data.isRoot) {
                    console.log('Cannot delete root node with Backspace.');
                    alert('Cannot delete the root node.');

                    event.preventDefault();
                    return;
                } else {
                    const hasChildren = nodes.some(
                        (node) => node.data.parentId === selectedNode.id
                    );

                    const confirmDelete = window.confirm(
                        hasChildren
                            ? 'This node has child nodes. Do you want to delete the parent and all child nodes?'
                            : 'Delete this node?'
                    );

                    if (confirmDelete) {
                        deleteNode();
                    }
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedNode, deleteNode, nodes]);

    const undo = () => {
        console.log('Undo clicked');
    };

    const redo = () => {
        console.log('Redo clicked');
    };

    const flagNode = (flag) => {
        updateNodeFlagStatusInDatabase(selectedNode.id, flag);
        setNodes((nds) =>
            nds.map((node) =>
                node.id === selectedNode.id
                    ? {
                          ...node,
                          data: { ...node.data, flagStatus: flag },
                      }
                    : node
            )
        );
    };

    const colorNode = (color) => {
        updateNodeColorInDatabase(selectedNode.id, color);
        setNodes((nds) =>
            nds.map((node) =>
                node.id === selectedNode.id
                    ? {
                          ...node,
                          style: { ...node.style, backgroundColor: color },
                      }
                    : node
            )
        );
    };

    const toggleHighlightPath = () => {
        setHighlightPath((prev) => {
            if (!prev) {
                setHighlightedNode(selectedNode);
            } else {
                setHighlightedNode(null);
            }
            return !prev;
        });
    };

    const findPathToRoot = useCallback(
        (leafId) => {
            const pathEdges = [];

            const traverse = (nodeId) => {
                const currentNode = nodes.find((node) => node.id === nodeId);
                if (currentNode) {
                    if (currentNode.data.parentId) {
                        const edgeId = `edge-${currentNode.data.parentId}-${currentNode.id}`;
                        pathEdges.push(edgeId);
                        traverse(currentNode.data.parentId);
                    }
                }
            };

            traverse(leafId);
            return pathEdges.reverse();
        },
        [nodes]
    );

    useEffect(() => {
        if (highlightPath && selectedNode) {
            setEdges((eds) =>
                eds.map((edge) => ({
                    ...edge,
                }))
            );
        } else {
            setEdges((eds) =>
                eds.map((edge) => ({
                    ...edge,
                }))
            );
        }
    }, [selectedNode, highlightPath, nodes, findPathToRoot, setEdges]);

    return (
        <div className="wrapper" ref={reactFlowWrapper}>
            <ReactFlow
                nodes={nodes.map((node) => ({
                    ...node,
                    data: { ...node.data, label: renderNodeContent(node) },
                    className:
                        selectedNode && selectedNode.id === node.id
                            ? 'selected'
                            : '', // 'selected' class if this node is selected
                    children: (
                        <>
                            {!node.data.isRoot && (
                                <Handle
                                    type="source"
                                    position="top"
                                    isConnectableStart={false}
                                    isConnectableEnd={false} // Disable connectability on the top handle
                                />
                            )}
                            <Handle
                                type="source"
                                position="bottom"
                                isConnectable={true} // Enable connectability on the bottom handle
                            />
                        </>
                    ),
                }))}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onConnectEnd={onConnectEnd}
                onNodeDragStop={onNodeDragStop}
                fitView
                fitViewOptions={{ padding: 2 }}
                nodeOrigin={nodeOrigin}
                onSelectionChange={(params) => {
                    const { nodes: selectedNodes } = params;
                    if (selectedNodes.length > 0) {
                        const newSelectedNode = selectedNodes[0];
                        setSelectedNode(newSelectedNode);
                        if (
                            highlightedNode &&
                            highlightedNode.id !== newSelectedNode.id
                        ) {
                            setHighlightPath(false);
                        }
                    } else {
                        setSelectedNode(null);
                    }
                }}
            />
            {selectedNode && ( // Only show Toolbar when a node is selected
                <Toolbar
                    addNode={addNode}
                    deleteNode={deleteNode}
                    undo={undo}
                    redo={redo}
                    minimize={toggleCollapse}
                    colorNode={colorNode}
                    flagNode={flagNode}
                    highlightPath={toggleHighlightPath}
                    selectedNode={selectedNode}
                    nodes={nodes}
                />
            )}
        </div>
    );
};

export default NodeManager;
