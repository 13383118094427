import React, { useState, useEffect, useRef } from "react";
import './css/OutcomeAndParameter.css';
import { addOutcomeToDatabase, addParameterToDatabase } from '../services/outcomesAndParametersService';

const OutcomesAndParameters = ({ nodeId, initialParameters, initialOutcomes }) => {
    const [outcomes, setOutcomes] = useState([{ name: '', values: [], successIcon: false }]);
    const [parameters, setParameters] = useState([{ name: '', value: '', successIcon: false }]);
    const [suggestions, setSuggestions] = useState([]);
    const [paramSuggestions, setParamSuggestions] = useState([]);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [isParamDropdownVisible, setParamDropdownVisible] = useState(false);
    const [activeOutcomeIndex, setActiveOutcomeIndex] = useState(null);
    const [activeParameterIndex, setActiveParameterIndex] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [selectedParamIndex, setSelectedParamIndex] = useState(-1);

    const [hoveredMean, setHoveredMean] = useState(null);
    const [hoveredMeanIndex, setHoveredMeanIndex] = useState(null);
    const [isSelectingSuggestion, setIsSelectingSuggestion] = useState(false);
    const [isSelectingParamSuggestion, setIsSelectingParamSuggestion] = useState(false);


    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    const dropdownRefParam = useRef(null);
    const inputRefParam = useRef(null);

    useEffect(() => {
        if (initialParameters?.length) {
            setParameters([
                ...initialParameters.map(parameter => ({
                    name: parameter.name || '',
                    value: parameter.value || '',
                    successIcon: false
                })),
                { name: '', value: '', successIcon: false },
            ]);
        }
        if (initialOutcomes?.length) {
            setOutcomes([...initialOutcomes.map(outcome => ({ ...outcome, values: Array.isArray(outcome.values) ? outcome.values : [], successIcon: false })),

                { name: '', values: [], isPopUpOpen: false },

            ])
        }
    }, [initialParameters, initialOutcomes]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current && !dropdownRef.current.contains(event.target) &&
                inputRef.current && !inputRef.current.contains(event.target)
            ) {
                setDropdownVisible(false);
                setParamDropdownVisible(false)
                setActiveOutcomeIndex(null);
                setActiveParameterIndex(null)
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const fetchSuggestions = async (query) => {
        if (!query || !nodeId) {
            setSuggestions([]);
            setDropdownVisible(false);
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/nodes/outcome/suggestions?query=${query}&nodeId=${nodeId}`);
            const data = await response.json();

            const uniqueSuggestions = Array.from(new Map(data.map(item => [item.name, item])).values());
            setSuggestions(uniqueSuggestions);
            setDropdownVisible(true);

        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
    };

    const fetchParameterSuggestions = async (query) => {
        if (!query || !nodeId) {
            setParamSuggestions([]);
            setParamDropdownVisible(false);
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/nodes/parameter/suggestions?query=${query}&nodeId=${nodeId}`);
            const data = await response.json();
            console.log('Fetched parameter suggestions:', data);

            const uniqueSuggestions = Array.from(new Map(data.map(item => [item.name, item])).values());
            setParamSuggestions(uniqueSuggestions);
            setParamDropdownVisible(true);

        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
    };


    const handleOutcomeInputs = (index, field, value) => {
        const newOutcomes = [...outcomes];
        if (field === 'values') {
            newOutcomes[index].values = value.split(',').map(val => val.trim());
        } else {
            newOutcomes[index][field] = value;
        }
        setOutcomes(newOutcomes);

        if (field === 'name') {
            fetchSuggestions(value);
            setDropdownVisible(true);
            setSelectedIndex(-1);
            setActiveOutcomeIndex(index)
        }
    };

    const handleKeyDown = (e, index) => {
        if (isDropdownVisible) {
            if (e.key === "ArrowDown") {
                e.preventDefault();
                setSelectedIndex((prev) => (prev + 1) % suggestions.length);
            } else if (e.key === "ArrowUp") {
                e.preventDefault();
                setSelectedIndex((prev) => (prev - 1 + suggestions.length) % suggestions.length);
            } else if (e.key === "Enter" && selectedIndex >= 0) {
                e.preventDefault();
                selectSuggestion(index, suggestions[selectedIndex]);
            }
        }
    };

    const handleAutoCompletion = () => {
        setTimeout(() => {
            if (!isSelectingSuggestion) {
                setDropdownVisible(false);
                setActiveOutcomeIndex(null);
            }
            setIsSelectingSuggestion(false);
        }, 150);
    };

    const handleParamAutoCompletion = () => {
        setTimeout(() => {
            if (!isSelectingParamSuggestion) {
                setParamDropdownVisible(false);
                setActiveParameterIndex(null);
            }
            setIsSelectingParamSuggestion(false);
        }, 150);
    };

    const selectParameterSuggestion = (index, suggestion) => {
        const updatedParameters = [...parameters];
        updatedParameters[index].name = suggestion.name;
        setParameters(updatedParameters);
        setParamDropdownVisible(false);
        setSelectedParamIndex(-1);
        setActiveParameterIndex(null)
    };

    const selectSuggestion = (index, suggestion) => {
        const updatedOutcomes = [...outcomes];
        updatedOutcomes[index].name = suggestion.name;
        setOutcomes(updatedOutcomes);
        setDropdownVisible(false);
        setSelectedIndex(-1);
        setActiveOutcomeIndex(null)
    };

    const handleParameterInputs = (index, field, value) => {
        const newParameter = [...parameters];
        newParameter[index][field] = value;
        setParameters(newParameter);

        if (field === 'name') {
            fetchParameterSuggestions(value);
            setParamDropdownVisible(true);
            setSelectedParamIndex(-1);
            setActiveParameterIndex(index)
        }
    };

    const addNewOutcomeValue = (index) => {
        const updatedOutcomes = [...outcomes];
        const currentOutcome = updatedOutcomes[index];

        if (currentOutcome.newValue?.trim()) {
            currentOutcome.values.push(currentOutcome.newValue.trim());
            currentOutcome.newValue = '';
            setOutcomes(updatedOutcomes);

            saveOutcome(index);
        }
    };

    const saveOutcome = (index) => {
        const outcomeToSave = outcomes[index];
        if (outcomeToSave.name && outcomeToSave.values.length > 0) {
            const outcomeData = {
                name: outcomeToSave.name,
                values: outcomeToSave.values,
            };
            console.log('Saving outcome:', outcomeData);
            addOutcomeToDatabase(nodeId, outcomeData).then((savedOutcome) => {
                if (savedOutcome) {
                    console.log('Outcome saved successfully:', savedOutcome);
                    const updatedOutcomes = [...outcomes];
                    updatedOutcomes[index].successIcon = true;

                    if (!outcomes.some(o => !o.name && !o.value)) {
                        updatedOutcomes.push({ name: '', values: [], successIcon: false });
                    }

                    setOutcomes(updatedOutcomes);

                    setTimeout(() => {
                        const updatedOutcomesAfterTimeout = [...updatedOutcomes];
                        updatedOutcomesAfterTimeout[index].successIcon = false;
                        setOutcomes(updatedOutcomesAfterTimeout);
                    }, 2000);
                }
            });
        } else {
            console.error('Outcome is incomplete, saving failed');
        }
    };

    const saveParameter = (index) => {
        const parameterToSave = parameters[index];
        if (parameterToSave.name && parameterToSave.value) {
            const parameterData = {
                name: parameterToSave.name,
                value: parameterToSave.value,
            };
            console.log('Saving parameter:', parameterData); // Check what you're sending
            addParameterToDatabase(nodeId, parameterData).then((savedParameter) => {
                if (savedParameter) {
                    console.log('Parameter saved successfully:', savedParameter);

                    const updatedParameters = [...parameters];
                    updatedParameters[index].successIcon = true;

                    if (!parameters.some(param => !param.name && !param.value)) {
                        updatedParameters.push({ name: '', value: '', successIcon: false });
                    }

                    setParameters(updatedParameters);

                    setTimeout(() => {
                        const updatedParametersAfterTimeout = [...updatedParameters];
                        updatedParametersAfterTimeout[index].successIcon = false;
                        setParameters(updatedParametersAfterTimeout);
                    }, 2000);

                }
            });
        } else {
            console.error('Parameter is incomplete, saving failed');
        }
    };

    const removeValue = (outcomeIndex, valueIndex) => {
        const updatedOutcomes = [...outcomes];
        updatedOutcomes[outcomeIndex].values = updatedOutcomes[outcomeIndex].values.filter((_, i) => i !== valueIndex);
        setOutcomes(updatedOutcomes);

        saveOutcome(outcomeIndex);
    };

    const calculateMean = (values) => {
        const numericValues = values.map(value => parseFloat(value)).filter(value => !isNaN(value));
        if (numericValues.length === 0) return null;
        const sum = numericValues.reduce((first, second) => first + second, 0);
        return sum / numericValues.length;
    };

    const handleMouseEnter = (index, values) => {
        setHoveredMean(calculateMean(values));
        setHoveredMeanIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredMean(null);
        setHoveredMeanIndex(null);
    };

    const toggleExpand = (index) => {
        const updatedOutcomes = outcomes.map((outcome, i) => {
            if (i === index) {
                return { ...outcome, isExpanded: !outcome.isExpanded };
            }
            return outcome;
        });
        setOutcomes(updatedOutcomes);
    };


    const handleParamKeyDown = (e, index) => {
        if (isParamDropdownVisible) {
            if (e.key === "ArrowDown") {
                e.preventDefault();
                setSelectedParamIndex((prev) => (prev + 1) % paramSuggestions.length);
            } else if (e.key === "ArrowUp") {
                e.preventDefault();
                setSelectedParamIndex((prev) => (prev - 1 + paramSuggestions.length) % paramSuggestions.length);
            } else if (e.key === "Enter" && selectedParamIndex >= 0) {
                e.preventDefault();
                selectParameterSuggestion(index, paramSuggestions[selectedParamIndex]);
            }
        }
    };


    return (
        <div>
            <div className="parameter-container">
                {parameters.map((parameter, index) => (
                    <div key={index} className="parameter-row">
                        <div className="parameter" style={{position: "relative"}}>
                            <input
                                type="text"
                                className={`border-input ${parameter.name ? 'filled' : ''}`}
                                placeholder="Parameter"
                                maxLength="20"
                                data-testid={`parameter-input-${index}`}
                                value={parameter.name}
                                onChange={(e) => handleParameterInputs(index, 'name', e.target.value)}
                                onFocus={() => {
                                    setActiveParameterIndex(index);
                                    fetchParameterSuggestions(parameter.name);
                                }}
                                onKeyDown={(e) => {
                                    handleParamKeyDown(e, index);
                                }}
                                onBlur={handleParamAutoCompletion}
                                ref={inputRefParam}
                            />
                            {isParamDropdownVisible && activeParameterIndex === index && paramSuggestions.length > 0 && (
                                <ul className="suggestions-dropdown" ref={dropdownRefParam}>
                                    {paramSuggestions.map((suggestion, i) => (
                                        <li
                                            key={i}
                                            className={i === selectedParamIndex ? "selected" : ""}
                                            onClick={() => selectParameterSuggestion(index, suggestion)}
                                        >
                                            {suggestion.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="parameter">
                            <input
                                type="text"
                                className={`border-input ${parameter.value ? 'filled' : ''}`}
                                placeholder="Value"
                                maxLength="20"
                                value={parameter.value}
                                onChange={(e) => handleParameterInputs(index, 'value', e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') saveParameter(index)
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <hr/>

            <div className="outcome-container">
                {outcomes.map((outcome, index) => (
                    <div key={index} className="outcome-row">
                        <div className="outcome-row-content">
                            <div className="outcome" style={{position: "relative"}}>
                                <input
                                    type="text"
                                    className={`border-input ${outcome.name ? 'filled' : ''}`}
                                    placeholder="Outcome"
                                    maxLength="20"
                                    data-testid={`outcome-input-${index}`}
                                    value={outcome.name}
                                    onChange={(e) => handleOutcomeInputs(index, 'name', e.target.value)}
                                    onFocus={() => {
                                        setActiveOutcomeIndex(index);
                                        fetchSuggestions(outcome.name);
                                    }}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    onBlur={handleAutoCompletion}
                                    ref={inputRef}
                                />
                                {isDropdownVisible && activeOutcomeIndex === index && suggestions.length > 0 && (
                                    <ul className="suggestions-dropdown" ref={dropdownRef}>
                                        {suggestions.map((suggestion, i) => (
                                            <li
                                                key={i}
                                                className={i === selectedIndex ? "selected" : ""}
                                                onClick={() => selectSuggestion(index, suggestion)}
                                            >
                                                {suggestion.name}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <div className={`value-container ${outcome.isExpanded ? 'expanded' : ''}`} onMouseEnter={() => handleMouseEnter(index, outcome.values)}
                                 onMouseLeave={handleMouseLeave} onClick={() => toggleExpand(index)}>
                                {outcome.values.map((value, i) => (
                                    <div key={i} className="value-item">
                                        <span className="value-text">{value}</span>
                                        <button className="remove-value-btn" onClick={() => removeValue(index, i)}>X</button>
                                    </div>
                                ))}
                                <div className="add-value">
                                    <input
                                        type="text"
                                        className="border-input"
                                        placeholder="Value"
                                        maxLength="20"
                                        value={outcome.newValue}
                                        onChange={(e) => {
                                            const updatedOutcomes = [...outcomes];
                                            updatedOutcomes[index].newValue = e.target.value; // Update specific outcome's newValue
                                            setOutcomes(updatedOutcomes);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                addNewOutcomeValue(index)}
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent click from closing the expanded container
                                        }}
                                    />
                                </div>

                            </div>
                            {hoveredMean !== null && hoveredMeanIndex === index && (
                                <div className="hover-mean">
                                    Mean: {hoveredMean.toFixed(2)}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OutcomesAndParameters;
