import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/LandingPage.css';

const LandingPage = () => {
    const navigate = useNavigate();
    const [isFadingOut, setIsFadingOut] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsFadingOut(true);
            const transitionTimer = setTimeout(() => {
                navigate('/login');
            }, 1000);

            return () => clearTimeout(transitionTimer);
        }, 2000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className={`landing-page ${isFadingOut ? 'fade-out' : ''}`}>
            <div className="fog fog1"></div>
            <div className="fog fog2"></div>
            <div className="fog fog3"></div>
            <h1>Mindflow</h1>
        </div>
    );
};

export default LandingPage;