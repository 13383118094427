import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import LandingPage from './components/LandingPage';
import ProjectsPage from './components/ProjectsPage';
import 'font-awesome/css/font-awesome.min.css';
import FlowPage from './components/FlowPage';
import LoginSignup from './components/LoginSignup';
import React, { useEffect, useState } from 'react';
import { fetchProjects } from './services/projectService';
import { UnauthorizedError } from './UnauthorizedError';

function App() {
    const [projects, setProjects] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getProjects = async () => {
            try {
                const data = await fetchProjects();
                if (data) {
                    setProjects(data);
                    setIsAuthenticated(true);
                }
            } catch (err) {
                if (err instanceof UnauthorizedError) {
                    setIsAuthenticated(false);
                }
                setError(err);
                console.error('Error fetching projects:', err);
            } finally {
                setLoading(false);
            }
        };

        getProjects();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error fetching projects: {error.message}</div>;
    }

    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={
                        isAuthenticated ? (
                            <Navigate to="/projects" />
                        ) : (
                            <LandingPage />
                        )
                    }
                />
                <Route
                    path="/login"
                    element={
                        <LoginSignup
                            setProjects={setProjects}
                            setIsAuthenticated={setIsAuthenticated}
                        />
                    }
                />
                <Route
                    path="/projects"
                    element={
                        isAuthenticated ? (
                            <ProjectsPage
                                projects={projects}
                                setProjects={setProjects}
                                setIsAuthenticated={setIsAuthenticated}
                            />
                        ) : (
                            <Navigate to="/login" />
                        )
                    }
                />
                <Route path="/flow/:projectId" element={<FlowPage />} />
            </Routes>
        </Router>
    );
}

export default App;
