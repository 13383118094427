const readNodeSummary = async (nodeId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/nodes/${nodeId}/summary`
        );
        const textResponse = await response.text();

        if (!response.ok) {
            throw new Error(`Failed to read summary`);
        }
        return JSON.parse(textResponse);
    } catch (error) {
        console.error(`Error reading summary:`, error);
        return null;
    }
};

export { readNodeSummary };
