// Toolbar.js
import React, { useState, useRef, useEffect } from 'react';
import './css/Toolbar.css'; // Import the CSS file
import { FiTrash } from 'react-icons/fi';
import { PiEmptyThin } from 'react-icons/pi';
import {
    TbPlus,
    TbArrowsDiagonalMinimize2,
    TbArrowsMaximize,
    TbFlag,
    TbPalette,
} from 'react-icons/tb';
import { CgSandClock } from 'react-icons/cg';
import { MdDone } from 'react-icons/md';
import { BiLoaderAlt } from 'react-icons/bi'; // Import curved arrow icons
import ConfirmationPopup from './ConfirmationPopup';
import { readNodeSummary } from '../services/summaryService';
import NodeSummaryModal from './NodeSummaryModal';

export default function Toolbar({
    addNode,
    deleteNode,
    undo,
    redo,
    minimize,
    flagNode,
    colorNode,
    highlightPath,
    selectedNode,
    nodes,
}) {
    const [isColorMenuOpen, setIsColorMenuOpen] = useState(false); // State for color menu visibility
    const [isFlagMenuOpen, setIsFlagMenuOpen] = useState(false); // State for flag menu visibility
    const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    // Refs to the color and flag menu buttons, to check outside clicks
    const colorMenuRef = useRef(null);
    const flagMenuRef = useRef(null);

    const toggleColorMenu = (event) => {
        event.stopPropagation(); // Prevent the click event from bubbling up and being handled as a "click outside"
        setIsColorMenuOpen(!isColorMenuOpen); // Toggle the visibility of the color submenu
        setIsFlagMenuOpen(false); // Close the flag menu when color menu is opened
    };

    const toggleFlagMenu = (event) => {
        event.stopPropagation(); // Prevent the click event from bubbling up and being handled as a "click outside"
        setIsFlagMenuOpen(!isFlagMenuOpen); // Toggle the visibility of the flag submenu
        setIsColorMenuOpen(false); // Close the color menu when flag menu is opened
    };

    const handleDeleteClick = () => {
        if (!selectedNode) return;
        if (selectedNode.data.isRoot) {
            alert('Cannot delete the root node.');
            return;
        }
        setIsConfirmDeleteVisible(true); // Show confirmation popup
    };

    const confirmDelete = () => {
        deleteNode();
        setIsConfirmDeleteVisible(false); // Hide confirmation popup after deletion
    };

    const showNodeSummary = async () => {
        try {
            const summary = await readNodeSummary(selectedNode.id);
            setModalContent(summary);
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error fetching node summary:', error);
        }
    };

    // Close menus when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                colorMenuRef.current &&
                !colorMenuRef.current.contains(event.target) &&
                flagMenuRef.current &&
                !flagMenuRef.current.contains(event.target)
            ) {
                setIsColorMenuOpen(false);
                setIsFlagMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="toolbar">
            <div className="leftButtons">
                <button
                    onClick={(event) => {
                        addNode();
                        setIsColorMenuOpen(false);
                        setIsFlagMenuOpen(false);
                    }}
                    title="Add node"
                >
                    <TbPlus />
                </button>
                <button
                    onClick={(event) => {
                        handleDeleteClick();
                        setIsColorMenuOpen(false);
                        setIsFlagMenuOpen(false);
                    }}
                    title="Delete node"
                >
                    <FiTrash />
                </button>
                {isConfirmDeleteVisible && (
                    <ConfirmationPopup
                        message={
                            nodes.some(
                                (node) => node.data.parentId === selectedNode.id
                            )
                                ? 'Delete this node and all its child nodes?'
                                : 'Delete this node?'
                        }
                        onConfirm={confirmDelete}
                        onCancel={() => setIsConfirmDeleteVisible(false)}
                    />
                )}
            </div>

            <div className="rightButtons">
                <button
                    onClick={(event) => {
                        setIsColorMenuOpen(false);
                        setIsFlagMenuOpen(false);
                        minimize();
                    }}
                    title="Collapse node"
                >
                    <TbArrowsDiagonalMinimize2 />
                </button>

                <div
                    style={{ position: 'relative', display: 'inline-block' }}
                    ref={flagMenuRef}
                >
                    <button onClick={toggleFlagMenu} title="Mark">
                        <TbFlag />
                    </button>
                    {isFlagMenuOpen && (
                        <div className="flagMenu">
                            <div className="flagButtons">
                                <button
                                    onClick={() => {
                                        flagNode('flagged');
                                        setIsFlagMenuOpen(false);
                                    }}
                                    title="Flag"
                                    className="flagButton"
                                >
                                    <TbFlag />
                                </button>
                                <button
                                    onClick={() => {
                                        flagNode('in-process');
                                        setIsFlagMenuOpen(false);
                                    }}
                                    title="In Process"
                                    className="flagButton"
                                >
                                    <BiLoaderAlt />
                                </button>
                                <button
                                    onClick={() => {
                                        flagNode('waiting');
                                        setIsFlagMenuOpen(false);
                                    }}
                                    title="Waiting"
                                    className="flagButton"
                                >
                                    <CgSandClock />
                                </button>
                                <button
                                    onClick={() => {
                                        flagNode('done');
                                        setIsFlagMenuOpen(false);
                                    }}
                                    title="Done"
                                    className="flagButton"
                                >
                                    <MdDone />
                                </button>
                                <button
                                    onClick={() => {
                                        flagNode(null);
                                        setIsFlagMenuOpen(false);
                                    }}
                                    title="No Flag"
                                    className="flagButton"
                                >
                                    <PiEmptyThin />
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                <div
                    style={{ position: 'relative', display: 'inline-block' }}
                    ref={colorMenuRef}
                >
                    <button onClick={toggleColorMenu} title="Color">
                        <TbPalette />
                    </button>
                    {isColorMenuOpen && (
                        <div className="colorMenu">
                            <div className="colorButtons">
                                <button
                                    onClick={() => {
                                        colorNode('#ffbdbd');
                                        setIsColorMenuOpen(false);
                                    }}
                                    title="Red"
                                    className="colorButton"
                                    style={{
                                        backgroundColor: '#EC221F',
                                        border: '1px solid #000',
                                    }}
                                />
                                <button
                                    onClick={() => {
                                        colorNode('#fff2ca');
                                        setIsColorMenuOpen(false);
                                    }}
                                    title="Yellow"
                                    className="colorButton"
                                    style={{
                                        backgroundColor: '#E8B931',
                                        border: '1px solid #000',
                                    }}
                                />
                                <button
                                    onClick={() => {
                                        colorNode('#dfffcc');
                                        setIsColorMenuOpen(false);
                                    }}
                                    title="Green"
                                    className="colorButton"
                                    style={{
                                        backgroundColor: '#1cc54b',
                                        border: '1px solid #000',
                                    }}
                                />
                                <button
                                    onClick={() => {
                                        colorNode('#ddf0fd');
                                        setIsColorMenuOpen(false);
                                    }}
                                    title="Blue"
                                    className="colorButton"
                                    style={{
                                        backgroundColor: '#5ebbff',
                                        border: '1px solid #000',
                                    }}
                                />
                                <button
                                    onClick={() => {
                                        colorNode('#dad1ff');
                                        setIsColorMenuOpen(false);
                                    }}
                                    title="Purple"
                                    className="colorButton"
                                    style={{
                                        backgroundColor: '#9550ff',
                                        border: '1px solid #000',
                                    }}
                                />
                                <button
                                    onClick={() => {
                                        colorNode('#ffc9a0');
                                        setIsColorMenuOpen(false);
                                    }}
                                    title="Orange"
                                    className="colorButton"
                                    style={{
                                        backgroundColor: '#ff7208',
                                        border: '1px solid #000',
                                    }}
                                />
                                <button
                                    onClick={() => {
                                        colorNode('#d7d7d7');
                                        setIsColorMenuOpen(false);
                                    }}
                                    title="Grey"
                                    className="colorButton"
                                    style={{
                                        backgroundColor: '#989898',
                                        border: '1px solid #000',
                                    }}
                                />
                                <button
                                    onClick={() => {
                                        colorNode('#ffdbfb');
                                        setIsColorMenuOpen(false);
                                    }}
                                    title="Pink"
                                    className="colorButton"
                                    style={{
                                        backgroundColor: '#ff3ef5',
                                        border: '1px solid #000',
                                    }}
                                />
                                <button
                                    onClick={() => {
                                        colorNode('#e5fff3');
                                        setIsColorMenuOpen(false);
                                    }}
                                    title="Mint"
                                    className="colorButton"
                                    style={{
                                        backgroundColor: '#6dffc1',
                                        border: '1px solid #000',
                                    }}
                                />
                            </div>
                            <div className="noColor">
                                <button
                                    onClick={() => {
                                        colorNode(null);
                                        setIsColorMenuOpen(false);
                                    }}
                                    title="No Color"
                                    className="colorButton"
                                >
                                    <PiEmptyThin />
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                <button
                    onClick={(event) => {
                        setIsColorMenuOpen(false);
                        setIsFlagMenuOpen(false);
                        highlightPath();
                        showNodeSummary();
                    }}
                    title="Full information"
                >
                    <TbArrowsMaximize />
                </button>
                <NodeSummaryModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    content={modalContent}
                />
            </div>
        </div>
    );
}
