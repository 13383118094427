const postToDatabase = async (endpoint, data, type) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });
        const textResponse = await response.text();

        if (!response.ok) {
            throw new Error(`Failed to save ${type}`);
        }
        return JSON.parse(textResponse);
    } catch (error) {
        console.error(`Error saving ${type}:`, error);
        return null;
    }
};

const addOutcomeToDatabase = (nodeId, outcomeData) => {
    return postToDatabase(`/nodes/outcome/${nodeId}`, outcomeData, 'outcome');
};

const addParameterToDatabase = (nodeId, parameterData) => {
    return postToDatabase(`/nodes/parameter/${nodeId}`, parameterData, 'parameter');
};

export { addOutcomeToDatabase, addParameterToDatabase };
