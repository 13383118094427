import { UnauthorizedError } from '../UnauthorizedError';

const createProject = async (projectData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/project`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(projectData),
            }
        );
        const textResponse = await response.text();
        console.log('Response Text:', textResponse);

        if (response.status === 401) {
            throw new UnauthorizedError('User is not authorized');
        }
        if (!response.ok) {
            throw new Error(`Failed to save project`);
        }
        return JSON.parse(textResponse);
    } catch (error) {
        console.error(`Error saving project:`, error);
        return null;
    }
};

const fetchProjects = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/project`,
            { credentials: 'include' }
        );
        if (response.status === 401) {
            throw new UnauthorizedError('User is not authorized');
        }
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Failed to fetch projects:', response.statusText);
        }
    } catch (error) {
        console.error('Error fetching projects:', error);
    }
};

const deleteProjects = async (selectedProjects) => {
    await Promise.all(
        Array.from(selectedProjects).map(async (id) => {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/project/${id}`,
                {
                    method: 'DELETE',
                    credentials: 'include',
                }
            );
            if (response.status === 401) {
                throw new UnauthorizedError('User is not authorized');
            }
            if (!response.ok) {
                console.error('Error deleting project:', response.statusText);
            }
        })
    );
};

const updateProject = async (id, updatedData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/project/${id}`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(updatedData),
            }
        );

        const textResponse = await response.text();
        console.log('Response Text:', textResponse);

        if (response.status === 401) {
            throw new UnauthorizedError('User is not authorized');
        }
        if (!response.ok) {
            throw new Error(`Failed to update project`);
        }

        return JSON.parse(textResponse);
    } catch (error) {
        console.error(`Error updating project:`, error);
        return null;
    }
};

export { fetchProjects, createProject, deleteProjects, updateProject };
