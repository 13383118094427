import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NodeManager from './NodeManager';
import {
    ReactFlow,
    Controls,
    Background,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import './css/FlowPage.css';
import SearchBar from './SearchBar';
import { FaHome, FaSearch, FaInfo } from 'react-icons/fa';
import {
    TbPlus,
    TbArrowsDiagonalMinimize2,
    TbFlag,
    TbPalette,
} from 'react-icons/tb';
import { BiTrash } from 'react-icons/bi';

export default function FlowPage() {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const [searchBar, setSearchBar] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSaveAndExit = () => {
        navigate('/projects');
    };
    const reverseCurrentSearchBarStatus = () => {
        setSearchBar(prevState => !prevState);
    }

    const disableSearchBar = () => {
        setSearchBar(false);
    };

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
        setCurrentSlide(0); // Reset to first slide when opened
    };

    const guideSlides = [
        {
            title: 'Hello, Welcome to Mindflow!',
            content: (
                <>
                    This is a place where you can track experiments, parameters, and outcomes using an
                    interactive mind map. Let&apos;s learn how to use it!
                </>
            ),
        },
        {
            title: 'How to Add Nodes',
            content: (
                <>
                    You can click on a node to make it active, and a toolbar appears. Click the
                    <TbPlus /> icon, or use the edge drop feature by dragging from the node&apos;s
                    <TbPlus /> icon and releasing it in the desired place.
                </>
            ),
        },
        {
            title: 'How to Delete Nodes',
            content: (
                <>
                    You can click the trash icon <BiTrash /> or use the backspace key. Be mindful that
                    if your node has child nodes, this will delete them. The root node cannot be deleted.
                </>
            ),
        },
        {
            title: 'Adding Parameters and Outcomes',
            content: (
                <>
                    You can add parameters and outcomes in the text field. When you enter them, they
                    are automatically saved.
                </>
            ),
        },
        {
            title: 'Viewing Outcomes',
            content: (
                <>
                    You can see the mean of an outcome by hovering over the value field. You can also
                    add more outcomes to the same value field by clicking on it.
                </>
            ),
        },
        {
            title: 'Collapsing Nodes and Customizing',
            content: (
                <>
                    You can collapse a node by clicking the <TbArrowsDiagonalMinimize2 /> icon in the
                    toolbar. You can also color nodes by clicking on the <TbPalette /> icon and add icons
                    by clicking on the <TbFlag /> icon.
                </>
            ),
        },
        {
            title: 'Viewing the Path to the Root',
            content: (
                <>
                    You can see the whole path from a node to the root, which shows all the current
                    parameters and their outcomes. This way, you can understand how and which parameters
                    led to the current node.
                </>
            ),
        },
    ];

    const handleNext = () => {
        if (currentSlide < guideSlides.length - 1) {
            setCurrentSlide((prev) => prev + 1);
        }
    };

    const handlePrev = () => {
        if (currentSlide > 0) {
            setCurrentSlide((prev) => prev - 1);
        }
    };

    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <div className="header">
                <div className="header-item header-title">Mindflow</div>
                <div className="header-item header-center">
                    <FaHome
                        className="icon home-icon"
                        onClick={handleSaveAndExit}
                        title="Home"
                    />
                </div>
                <div className="header-item header-right">
                    <div className="dropdown-container">
                        <FaInfo
                            className="icon info-icon"
                            onClick={toggleDropdown}
                            title="Info"
                        />
                        {isDropdownOpen && (
                            <div className="dropdown-guide">
                                <div className="guide-slide">
                                    <h4>{guideSlides[currentSlide].title}</h4>
                                    <p>{guideSlides[currentSlide].content}</p>
                                </div>
                                <div className="guide-controls">
                                    <button
                                        className={`prev-btn ${currentSlide === 0 ? 'disabled' : ''}`}
                                        onClick={handlePrev}
                                        disabled={currentSlide === 0}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        className={`next-btn ${currentSlide === guideSlides.length - 1 ? 'disabled' : ''}`}
                                        onClick={handleNext}
                                        disabled={currentSlide === guideSlides.length - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    {searchBar && (
                        <SearchBar
                            placeholder="Search nodes..."
                            onSearch={handleSearch}
                            searchTerm={searchTerm}
                        />
                    )}
                    <FaSearch
                        className="icon search-icon"
                        title="Search"
                        onClick={reverseCurrentSearchBarStatus}
                    />
                </div>
            </div>
            <ReactFlow>
                <Controls />
                <Background variant="dots" gap={12} size={1} />
                <NodeManager
                    projectId={projectId}
                    searchTerm={searchTerm}
                    disableSearchBar={disableSearchBar}
                />
            </ReactFlow>
        </div>
    );
}
