import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/ProjectsPage.css';
import { createProject, deleteProjects, updateProject } from '../services/projectService';
import { UnauthorizedError } from '../UnauthorizedError';

const ProjectsPage = ({ projects, setProjects, setIsAuthenticated }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [selectMode, setSelectMode] = useState(false);
    const [selectedProjects, setSelectedProjects] = useState(new Set());
    const [editingProjectId, setEditingProjectId] = useState(null);
    const [editedName, setEditedName] = useState('');
    const inputRef = useRef(null);

    const handlePlusClick = () => {
        setIsOpen(true);
        setErrorMessage('');
    };

    const handleCreateProject = async () => {
        if (!projectName.trim()) {
            setErrorMessage('Project name is required.');
            return;
        }

        let response;
        try {
            response = await createProject({
                name: projectName,
                description: projectDescription,
            });
        } catch (error) {
            if (error instanceof UnauthorizedError) {
                navigate('/login');
            }
            console.error('An error occurred:', error);
            return;
        }

        if (response) {
            setProjects((prevProjects) => [response, ...prevProjects]);
            setProjectName('');
            setProjectDescription('');
            setIsOpen(false);
            navigate(`/flow/${response.id}`);
        }
    };

    const handleSelectProject = (id) => {
        const updatedSelection = new Set(selectedProjects);
        if (updatedSelection.has(id)) {
            updatedSelection.delete(id);
        } else {
            updatedSelection.add(id);
        }
        setSelectedProjects(updatedSelection);
    };

    const handleSelectModeToggle = () => {
        if (selectMode) {
            setSelectedProjects(new Set());
        }
        setSelectMode(!selectMode);
    };

    const handleDeleteProjects = async () => {
        if (selectedProjects.size === 0) return;

        const confirmDelete = window.confirm(
            'Are you sure you want to delete the selected projects? This action is irreversible.'
        );
        if (!confirmDelete) return;

        try {
            await deleteProjects(selectedProjects);
        } catch (error) {
            if (error instanceof UnauthorizedError) {
                navigate('/login');
            }
            console.error('Error deleting projects:', error);
            return;
        }

        const updatedProjects = projects.filter(
            (project) => !selectedProjects.has(project.id)
        );

        setProjects(updatedProjects);
        setSelectedProjects(new Set());
        setSelectMode(false);
    };

    const handleLogout = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/user/logout`, {
                    method: 'DELETE',
                    credentials: 'include',
                });

            if (response.ok) {
                setIsAuthenticated(false);
                navigate('/login');
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const handleEditClick = (projectId, currentName) => {
        setEditingProjectId(projectId);
        setEditedName(currentName);

        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(currentName.length, currentName.length);
            }
        }, 0);
    };

    const handleNameChange = (e) => {
        setEditedName(e.target.value);
    };

    const handleNameSubmit = async (projectId) => {
        if (!editedName.trim()) return;

        try {
            const updatedProject = await updateProject(projectId, { name: editedName });
            setProjects((prevProjects) =>
                prevProjects.map((project) =>
                    project.id === projectId ? { ...project, name: updatedProject.name } : project
                )
            );
            setEditingProjectId(null);
        } catch (error) {
            if (error instanceof UnauthorizedError) {
                navigate('/login');
            }
            console.error('Error updating project name:', error);
        }
    };

    const handleKeyDown = (e, projectId) => {
        if (e.key === 'Enter') {
            handleNameSubmit(projectId);
        } else if (e.key === 'Escape') {
            setEditingProjectId(null);
        }
    };

    return (
        <div className="projects-container">
            <div className="fog1"></div>
            <div className="fog2"></div>
            <div className="fog3"></div>
            <div className="fog4"></div>

            <main className="main-content">
                <h2 className="page-title">Projects</h2>

                <button className="logout-button" onClick={handleLogout}>
                    Logout
                </button>

                <div className="action-buttons">
                    <button
                        className="styled-button"
                        onClick={handleSelectModeToggle}
                    >
                        {selectMode ? 'Cancel' : 'Select'}
                    </button>

                    {selectMode && (
                        <button
                            className="styled-button delete-button"
                            onClick={handleDeleteProjects}
                        >
                            Delete
                        </button>
                    )}
                </div>

                <div className="projects-grid">
                    <div
                        className="project-card create-project-card"
                        onClick={handlePlusClick}
                    >
                        <div className="create-box">
                            <span className="plus-sign">+</span>
                        </div>
                        <p className="create-text">Create</p>
                    </div>

                    {projects.map((project) => (
                        <div key={project.id} className="project-card">
                            <div
                                className={`project-box ${
                                    selectMode ? 'selectable' : ''
                                } ${
                                    selectedProjects.has(project.id)
                                        ? 'selected'
                                        : ''
                                }`}
                                onClick={() => {
                                    if (selectMode) {
                                        handleSelectProject(project.id);
                                    } else {
                                        navigate(`/flow/${project.id}`);
                                    }
                                }}
                            >
                                <input
                                    type="checkbox"
                                    checked={selectedProjects.has(project.id)}
                                    onChange={() =>
                                        handleSelectProject(project.id)
                                    }
                                    className="custom-checkbox"
                                />
                                <span className="checkmark">✔</span>
                            </div>
                            {editingProjectId === project.id ? (
                                <input
                                    ref={inputRef}
                                    type="text"
                                    value={editedName}
                                    onChange={handleNameChange}
                                    onKeyDown={(e) => handleKeyDown(e, project.id)}
                                    onBlur={() => setEditingProjectId(null)}
                                    className="edit-input"
                                />
                            ) : (
                                <h3
                                    className="project-title"
                                    onClick={() => handleEditClick(project.id, project.name)}
                                >
                                    {project.name}
                                </h3>
                            )}
                            <p className="project-description">
                                {project.description}
                            </p>
                        </div>
                    ))}
                </div>

                {isOpen && (
                    <div className="project-modal">
                        <input
                            className="input-box"
                            type="text"
                            placeholder="Project Name"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                        />
                        <textarea
                            className="input-box"
                            placeholder="Project Description"
                            value={projectDescription}
                            onChange={(e) =>
                                setProjectDescription(e.target.value)
                            }
                        />
                        {errorMessage && (
                            <p className="error-message">{errorMessage}</p>
                        )}
                        <div className="modal-buttons">
                            <button
                                className="styled-button cancel-button"
                                onClick={() => setIsOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="styled-button"
                                onClick={handleCreateProject}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                )}
            </main>
        </div>
    );
};

export default ProjectsPage;

