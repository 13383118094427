import {useState} from "react";
import './css/LoginSignup.css';
import {useNavigate} from "react-router-dom";
import { fetchProjects } from '../services/projectService';
import loginPicture from '../loginPicture.png';

const LoginSignup = ({ setProjects , setIsAuthenticated}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')
    const [userName, setUsername] = useState('');
    const [view, setView] = useState('login');
    const navigate = useNavigate();
    const [requiredFieldError, setRequiredFieldError] = useState('');

    const handleViewToggle = () => {
        setView(view === 'login' ? 'signup' : 'login');
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const saveToDatabase = async (userData) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/user/add`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(userData),
                }
            );
            if (response.status !== 204) {
                setRequiredFieldError('Registration failed. Try a different username or email');
                throw new Error('Failed to save user');
            }
            setRequiredFieldError('')
            navigate('/')
        }catch(error) {
            console.error('Error saving user', error)
            return null;
        }
    }

    const handleSignUp = () => {
        if (!userName || !email || !password || !confirmPassword) {
            setRequiredFieldError('All fields are required!');
            return;
        }

        if (!isValidEmail(email)) {
            setRequiredFieldError('Please enter valid email address!')
            return;
        }

        if (password !== confirmPassword) {
            setRequiredFieldError('Passwords do not match!')
            return;
        }

        const userData = {
            userName,
            email,
            password,
        };

        saveToDatabase(userData);
    }

    const handleLogIn = async () => {
        if (!email || !password) {
            setRequiredFieldError('Email and password are required.');
            return;
        }
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/user/login`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email, password }),
                }
            );
            if (!response.ok) {
                if (response.status === 404) {
                    setRequiredFieldError('User does not exist, please sign up')
                }else if (response.status === 401) {
                    setRequiredFieldError('Wrong email or password')
                    setIsAuthenticated(false);
                }else {
                    setRequiredFieldError('Login failed')
                }
                return
            }
            setIsAuthenticated(true);
            console.log(response)
            const data = await fetchProjects();
            if (data) {
                setProjects(data);
            }
            navigate('/projects');
        } catch (error) {
            console.error('Login failed:', error);
            setRequiredFieldError('An error occured, please try again')
        }
    }

    const handleSubmit = () => {
        if (view === 'signup') {
            handleSignUp();
        } else {
            handleLogIn();
        }
    };

    return (
        <div className="page-container">
            <div className="picture-container">
                <img src={loginPicture} alt="loginPicture"></img>
            </div>
            <div className="form-container">
                <h2>Mindflow</h2>
                <p className="description">Mindflow is a web-based tool that helps users track experiments, parameters, and outcomes using an interactive mind map. Tailored for iterative experimentation, it allows users to visualize configurations, trace parameter changes, and integrate with tools for seamless tracking.</p>
                <div className="inputs">
                    {view === 'signup' && (
                        <div className="input">
                            <input
                                type="text"
                                placeholder="Your username"
                                value={userName}
                                onChange={(e) => setUsername(e.target.value)}
                                className="input-field"
                            />
                        </div>
                    )}
                    <div className="input">
                        <input
                            type="email"
                            placeholder="Your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="input-field"
                        />
                    </div>
                    <div className="input">
                        <input
                            type="password"
                            placeholder="Your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input-field"
                        />
                    </div>
                    {view === 'signup' && (
                        <div className="input">
                            <input
                                type="password"
                                placeholder="Confirm password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="input-field"
                            />
                        </div>
                    )}
                </div>
                {requiredFieldError && <p className="error">{requiredFieldError}</p>}
                <button className="button" onClick={handleSubmit}>
                    {view === 'login' ? 'Log In' : 'Sign Up'}
                </button>

                <div className="divider">or</div>

                <button onClick={handleViewToggle} className="other-button">
                    {view === 'login' ? 'Sign Up' : 'Log In'}
                </button>
            </div>
        </div>
    );
}

export default LoginSignup;
