import React, { useRef, useState, useEffect } from "react";
import "./css/Modal.css";

const DraggableModal = ({ isOpen, onClose, content }) => {
    const modalRef = useRef(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        if (modalRef.current) {
            const { innerWidth, innerHeight } = window;
            const modalRect = modalRef.current.getBoundingClientRect();
            setPosition({
                x: (innerWidth - modalRect.width) / 2,
                y: (innerHeight - modalRect.height) / 2,
            });
        }
    }, [isOpen]);
    const handleMouseDown = (e) => {
        if (modalRef.current && e.target.closest(".modal-top-bar")) {
            setIsDragging(true);
            setOffset({
                x: e.clientX - position.x,
                y: e.clientY - position.y,
            });
        }
    };
    const handleMouseMove = (e) => {
        if (isDragging) {
            setPosition({
                x: e.clientX - offset.x,
                y: e.clientY - offset.y,
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
            <div className="modal" ref={modalRef}
                 style={{
                     position: "absolute",
                     left: `${position.x}px`,
                     top: `${position.y}px`,
                 }}
                 onMouseDown={handleMouseDown}>
                <div className="modal-top-bar">
                    <button onClick={onClose}>Close</button>
                </div>
                <div className="modal-content">
                    <h2>Parameters</h2>
                    <table className="parameters-table">
                        <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Value</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.entries(
                            content.summaryFromRootToNode.parameters
                        ).map(([key, value]) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{value}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <h2>Outcomes</h2>
                    <table className="outcomes-table">
                        <thead>
                        <tr>
                            <th>Outcome</th>
                            <th>Values</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.entries(
                            content.summaryFromRootToNode.outcomes
                        ).map(([key, value]) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{value.join(", ")}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
export default DraggableModal;