import React, { useState } from 'react';
import './css/SearchBar.css';

export default function SearchBar({ placeholder, onSearch, searchTerm }) {
    const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm || '');

    const handleSearch = (e) => {
        setLocalSearchTerm(e.target.value);
        onSearch(e.target.value);
    };

    return (
        <div className="search-bar-container">
            <input
                type="text"
                className="search-bar-input"
                value={localSearchTerm}
                onChange={handleSearch}
                placeholder={placeholder}
            />
        </div>
    );
}
