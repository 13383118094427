import React from 'react';
import './css/Toolbar.css';

const ConfirmationPopup = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="confirmationPopup">
            <span>{message}</span>
            <div>
                <button className="yes-button" onClick={onConfirm}>
                    Yes
                </button>
                <button className="no-button" onClick={onCancel}>
                    No
                </button>
            </div>
        </div>
    );
};

export default ConfirmationPopup;
